import React, { Component } from 'react'
import {
  Modal,
  Button,
  Form,
  FormGroup,
  FormControl,
  HelpBlock,
  ControlLabel,
  Grid,
  Row,
  Col,
} from 'react-bootstrap'
import MDSpinner from 'react-md-spinner'
import VendorService from '../../services/VendorService'

export default class SubVendorEditorModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formData: {
        id: null,
        surname: null,
        givenName: null,
        email: null,
      },
      loading: false,
      validate: false,
      validateKeys: {},
      error: null,
    }
  }

  componentDidMount = () => {
    this.resetForm()
  };

  resetForm = () => {
    this.setState({
      formData: {
        id: null,
        surname: null,
        givenName: null,
        email: null,
      },
      loading: false,
      validate: false,
      error: null,
    })
  };

  handleFieldChange = (e) => {
    const data = Object.assign({}, this.state.formData)
    data[e.target.name] = e.target.value || null
    this.setState({ formData: data })
  };

  hideConfirmationModal = (...args) => {
    this.resetForm()
    this.props.closeModal && this.props.closeModal(...args)
  };

  onSave = () => {
    this.setState({ validate: true }, this.save)
  }

  save = async() => {
    const allValid = this.validateAll()

    if (!allValid) {
      return
    }

    try {
      this.setState({ loading: true })
      const {
        surname,
        givenName,
        email,
      } = this.state.formData
      await VendorService.saveSubVendorUser(this.props.idVendor, this.props.idSubVendor, {
        surname,
        givenName,
        email,
      })
      this.hideConfirmationModal(true)
    } catch (err) {
      console.warn('ERROR-->', this.state.formData)
    } finally {
      this.setState({ loading: false })
    }
  };

  validateRequired = (key) => {
    if (this.state.validate) {
      if (!this.state.formData?.[key]) {
        return 'error'
      }
    }
    return null
  };

  validateAll = () =>
    ['surname', 'givenName', 'email']
      .map((key) => this.validateRequired(key))
      .reduce((acc, val) => acc && !val, true)

  render() {
    const { openModal } = this.props
    const { formData, loading } = this.state
    return (
      <Modal
        className="static-modal dates-request-reject"
        bsSize="small"
        show={openModal}
        onHide={this.hideConfirmationModal}
      >
        {loading && (
          <div className="spinner-container overlay">
            <MDSpinner className="spinner" size={30} />
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>{this.props.user ? 'Edit' : 'Create'} User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form horizontal style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 236px)' }}>
            <Grid>
              <Row>
                <Col xs={12} sm={12}>
                  <FormGroup
                    controlId="givenName"
                    validationState={this.validateRequired('givenName') && 'error'}
                  >
                    <ControlLabel>First Name</ControlLabel>
                    <FormControl
                      name="givenName"
                      placeholder="First Name"
                      value={formData.givenName}
                      onChange={this.handleFieldChange}
                    />
                    {{
                      error: <HelpBlock>Required</HelpBlock>,
                    }[this.validateRequired('givenName')]}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12}>
                  <FormGroup
                    controlId="surname"
                    validationState={this.validateRequired('surname') && 'error'}
                  >
                    <ControlLabel>Last Name</ControlLabel>
                    <FormControl
                      name="surname"
                      placeholder="Last Name"
                      value={formData.surname}
                      onChange={this.handleFieldChange}
                    />
                    {{
                      error: <HelpBlock>Required</HelpBlock>,
                    }[this.validateRequired('surname')]}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12}>
                  <FormGroup
                    controlId="email"
                    validationState={this.validateRequired('email') && 'error'}
                  >
                    <ControlLabel>Email</ControlLabel>
                    <FormControl
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={this.handleFieldChange}
                    />
                    {{
                      error: <HelpBlock>Required</HelpBlock>,
                    }[this.validateRequired('email')]}
                  </FormGroup>
                </Col>
              </Row>
            </Grid>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button bsSize="large" onClick={this.hideConfirmationModal}>
            Cancel
          </Button>
          <Button
            bsSize="large"
            bsStyle="primary"
            active={false}
            onClick={this.onSave}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
