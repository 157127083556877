import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'

export default class SpecialRequestsResponseModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      guestSpecialRequestsVendorResponse: '',
    }
  }

  render() {
    const {
      selectedGuestSpecialRequests,
      selectedGuestEmail,
      selectedGuestSpecialRequestsVendorHasResponded,
      selectedOrderId,
      selectedOrderItemId,
      showGuestSpecialRequestsResponseModal,
      closeGuestSpecialRequestsResponseModal,
      sendGuestSpecialRequestsResponse,
      selectedGuestSpecialRequestsVendorResponse,
    } = this.props

    return (
      <Modal
        className="static-modal dates-request-reject"
        bsSize="small"
        show={showGuestSpecialRequestsResponseModal}
        onHide={closeGuestSpecialRequestsResponseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Respond to Customer's Special Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>Customer's Request</b>
          </p>
          <textarea
            className="form-control"
            id="sp-login-0"
            name="login"
            value={selectedGuestSpecialRequests}
            required
            disabled
            rows={4}
            style={{
              height: '150px',
              verticalAlign: 'top',
              paddingTop: '8px',
            }}
          />
          <br />
          <p>
            Your response will be emailed directly to the customer's email{' '}
            {selectedGuestEmail}. Please note:
          </p>
          <br />
          <ul>
            <li>
              Customers have been informed that their request is not guaranteed.
            </li>
            <li>
              Kindly let them know if you can accommodate the request and ensure
              it is noted in your system.
            </li>
          </ul>
          <br />
          <p>
            <b>Respond to customer</b>
          </p>
          <textarea
            className="form-control"
            id="sp-login-0"
            name="login"
            placeholder="Special request response..."
            value={
              selectedGuestSpecialRequestsVendorHasResponded ?
                selectedGuestSpecialRequestsVendorResponse :
                this.state.guestSpecialRequestsVendorResponse
            }
            onChange={(e) =>
              this.setState({
                guestSpecialRequestsVendorResponse: e.target.value,
              })
            }
            required
            disabled={selectedGuestSpecialRequestsVendorHasResponded}
            rows={4}
            style={{
              height: '150px',
              verticalAlign: 'top',
              paddingTop: '8px',
            }}
          />
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
          <Button
            bsSize="large"
            bsStyle="default"
            className="btn-dates-request"
            onClick={closeGuestSpecialRequestsResponseModal}
          >
            Cancel
          </Button>
          { !selectedGuestSpecialRequestsVendorHasResponded &&
            <Button
              bsSize="large"
              bsStyle="primary"
              className="btn-primary btn-dates-request"
              onClick={() =>
                sendGuestSpecialRequestsResponse(
                  this.state.guestSpecialRequestsVendorResponse,
                  selectedOrderId,
                  selectedOrderItemId,
                )
              }
            >
              Send
            </Button>
          }
        </Modal.Footer>
      </Modal>
    )
  }
}
