import { request } from './common'
import qs from 'qs'
import filterRemittance from '../utils/filterRemittance'
import fileDownload from 'js-file-download'
import contentDisposition from 'content-disposition'

export default class VendorService {
  static get(idVendor) {
    const requestUri = window.configs.VENDOR_HOST + '/api/vendor/' + idVendor
    return request(requestUri, { method: 'GET' })
  }

  static getRemittance(vendorId, limit = 200) {
    const queryParams = {
      limit,
    }

    queryParams.vendor_id = vendorId

    const url = `${window.configs.VENDOR_HOST}/api/vendor/remittance?${qs.stringify(queryParams)}`

    return request(url, { method: 'GET' })
  }

  static async getAllRemittance(emailSent, vendorId, limit = 200) {
    const remittance = await this.getRemittance(vendorId, limit)

    const result = filterRemittance(
      remittance.result,
      emailSent,
    )

    return result
  }

  static async downloadRemittance(id, type = 'pdf', vendorId) {
    const queryParams = {
      type,
      vendor_id: vendorId,
    }

    // eslint-disable-next-line no-undef
    const response = await fetch(
      `${window.configs.VENDOR_HOST}/api/vendor/remittance/${id}/download?${qs.stringify(queryParams)}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      },
    )

    const body = await response.blob()

    const fileNameHeader = contentDisposition.parse(
      response.headers.get('content-disposition'),
    )

    await fileDownload(body, fileNameHeader.parameters.filename)
  }

  static async listSubVendors(vendorId, page, perPage) {
    return request(
      `${window.configs.VENDOR_HOST}/api/vendor/${vendorId}/sub-vendors?${
        qs.stringify({ page, perPage })
      }`, { method: 'GET' })
  }

  static async getSubVendor(vendorId, subVendorId) {
    return request(
      `${window.configs.VENDOR_HOST}/api/vendor/${vendorId}/sub-vendors/${subVendorId}`, { method: 'GET' },
    )
  }

  static async listSubVendorUsers(vendorId, subVendorId, page, perPage) {
    return request(
      `${window.configs.VENDOR_HOST}/api/vendor/${vendorId}/sub-vendors/${subVendorId}/users?${
        qs.stringify({ page, perPage })
      }`, { method: 'GET' })
  }

  static async saveSubVendorUser(vendorId, subVendorId, data) {
    return request(
      `${window.configs.VENDOR_HOST}/api/vendor/${vendorId}/sub-vendors/${subVendorId}/users`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      },
    )
  }

  static async deleteSubVendorUser(vendorId, subVendorId, userId) {
    return request(
      `${window.configs.VENDOR_HOST}/api/vendor/${vendorId}/sub-vendors/${subVendorId}/users/${userId}`,
      { method: 'DELETE' },
    )
  }
}
