export const ADDON_TYPE = 'addon'

export const VENDORS_WITH_UPCOMING_OFFERS_WITH_ADDONS = [
  '0010I00002J0Bb0QAF',
  '0010I000026fcwcQAA',
  '0010I00002HDYoVQAX',
  '0012y00000FNV6cAAH',
  '001Ii000002X8gfIAC',
  '0010I00002DIbQCQA1',
  '0010I000026fhISQAY',
  '0012y00000C6ek8AAB',
  '0010I00002DIbRZQA1',
  '0012y00000LJhQkAAL',
  '0010I000020OOmTQAW',
  '00128000005cL1YAAU',
  '0012y00000bn92DAAQ',
  '0012y00000iqwiEAAQ',
  '001RF00000Hc1aoYAB',
  '001Ii000002XZeKIAW',
  '0012y00000AVYMjAAP',
  '0012y00000jTPUjAAO',
  '00128000005c6SKAAY',
  '00128000005c3ZoAAI',
  '0012y00000hvEG3AAM',
  '0012y00000tqM8TAAU',
  '00128000007TAaLAAW',
  '0012y00000MrIgFAAV',
  '0012y00000PIUaOAAX',
  '0012y00000jJsHtAAK',
  '0012y00000W10dWAAR',
  '0012y000005bUC8AAM',
  '0012y00000fkPfeAAE',
  '00128000005cC5aAAE',
  '0012y0000045emoAAA',
  '0012y00000iNKZ6AAO',
  '0010I00002Fmfb9QAB',
  '0012y00000dbe3UAAQ',
  '0012y00000jL5ArAAK',
  '0012y00000dfMKcAAM',
  '0012y00000T8OnbAAF',
]
