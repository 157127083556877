import React, { Component } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import OrderService from '../../services/OrderService'
import GuestsCell from '../Common/GuestsCell'
import MDSpinner from 'react-md-spinner'
import { Button } from 'react-bootstrap'
import SpecialRequestsResponseModal from '../Common/SpecialRequestsResponseModal'
import ExpandableText from '../Common/ExpandableText'
import moment from 'moment'

export default class SpecialRequests extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1,
      sizePerPage: 10,
      totalSpecialRequests: 0,
      loading: true,
      specialRequests: [],
      error: null,
      showGuestSpecialRequestsResponseModal: false,
      selectedGuestSpecialRequests: '',
      selectedGuestSpecialRequestsVendorResponse: '',
      selectedGuestSpecialRequestsVendorHasResponded: false,
      selectedOrderId: '',
      selectedOrderItemId: '',
      selectedGuestEmail: '',
    }

    this.vendorId = props.params.id_vendor
  }

  fetchData = async() => {
    this.setState({ loading: true })

    try {
      const { result: specialRequests, total: totalSpecialRequests } =
        await OrderService.getVendorSpecialRequests({
          vendorId: this.vendorId,
          page: this.state.page,
          limit: this.state.sizePerPage,
        })

      const orderIds = [
        ...new Set(
          specialRequests.map((specialRequestsOrderItem) => specialRequestsOrderItem.fk_order_id),
        ),
      ]

      const orders = await OrderService.getOrdersByIds(orderIds)

      const updatedSpecialRequestsOrderItems = specialRequests.map((specialRequestsOrderItem) => {
        const matchedOrder = orders.find((order) => order.id === specialRequestsOrderItem.fk_order_id)

        const customerNameTrimmed = matchedOrder?.customer_full_name?.trim() || ''
        const customer_name = customerNameTrimmed || matchedOrder?.customer_email || 'N/A'

        const offer_link = 'https://' + matchedOrder?.brand + '.com/offer/' + specialRequestsOrderItem?.offer?.slug + '/' + specialRequestsOrderItem?.offer?.id_salesforce_external

        return {
          ...specialRequestsOrderItem,
          customer_name,
          offer_link,
        }
      })

      this.setState({
        loading: false,
        specialRequests: updatedSpecialRequestsOrderItems,
        totalSpecialRequests,
      })
    } catch (error) {
      this.setState({
        loading: false,
        error: error.message,
      })
    }
  };

  componentDidMount() {
    this.fetchData()
  }

  renderPrimaryGuest(_, row) {
    return (
      <div>
        {row.customer_name}
      </div>
    )
  }

  renderGuests(_, row) {
    return <GuestsCell row={row.reservation} />
  }

  renderPackage(_, row) {
    return <div>{row.offer.name}</div>
  }

  renderCreatedAtDate(_, row) {
    const formattedDate = moment
      .utc(row.reservation.created_at)
      .format('DD/MM/YYYY')
    return <div>{formattedDate}</div>
  }

  renderGuestSpecialRequest(_, row) {
    return <ExpandableText value={row.reservation.guest_special_requests} />
  }

  renderRoomName(_, row) {
    return <div>{row.reservation_response_json.result.room_type.name}</div>
  }

  renderCheckIn(_, row) {
    const formattedDate = moment
      .utc(row.reservation.check_in)
      .format('DD/MM/YYYY')
    return <div>{formattedDate}</div>
  }

  renderCheckOut(_, row) {
    const formattedDate = moment
      .utc(row.reservation.check_out)
      .format('DD/MM/YYYY')
    return <div>{formattedDate}</div>
  }

  sendGuestSpecialRequestsResponse = async(
    guestSpecialRequestsVendorResponse,
    orderId,
    itemId,
  ) => {
    try {
      await OrderService.patchGuestSpecialRequestsResponse({
        guestSpecialRequestsVendorResponse,
        orderId,
        itemId,
      })

      this.setState({
        loading: false,
        showGuestSpecialRequestsResponseModal: false,
        specialRequests: this.state.specialRequests.map((specialRequest) => {
          if (specialRequest.id_items === itemId) {
            return {
              ...specialRequest,
              reservation: {
                ...specialRequest.reservation,
                guest_special_requests_vendor_response:
                  guestSpecialRequestsVendorResponse,
                guest_special_requests_vendor_has_responded: true,
              },
            }
          }
          return specialRequest
        }),
      })
    } catch (error) {
      this.setState({
        loading: false,
        showGuestSpecialRequestsResponseModal: false,
        error: error.message,
      })
    }
  };

  closeGuestSpecialRequestsResponseModal = () => {
    this.setState({
      showGuestSpecialRequestsResponseModal: false,
    })
  };

  renderOfferLink(_, row) {
    return (
      <div>
        {row.offer?.id_salesforce_external}{' '}
        <a href={row.offer_link} className="link" target="_blank">
          (View)
        </a>
      </div>
    )
  }

  renderAction = (_, row) => (
    <div>
      <Button
        className="btn-dates-request"
        bsStyle={
          row.reservation.guest_special_requests_vendor_has_responded ?
            'default' :
            'success'
        }
        bsSize="xsmall"
        onClick={() => {
          this.setState({
            selectedGuestSpecialRequests:
              row.reservation.guest_special_requests,
            selectedGuestSpecialRequestsVendorResponse:
              row.reservation.guest_special_requests_vendor_response,
            selectedGuestSpecialRequestsVendorHasResponded:
              row.reservation.guest_special_requests_vendor_has_responded,
            selectedGuestEmail: row.reservation.guest_email,
            selectedOrderItemId: row.id_items,
            selectedOrderId: row.fk_order_id,
            showGuestSpecialRequestsResponseModal: true,
          })
        }}
      >
        {row.reservation.guest_special_requests_vendor_has_responded ?
          'View Response' :
          'Respond'}
      </Button>
    </div>
  );

  onPageChange = (page) => {
    this.setState({ page }, this.fetchData)
  };

  onSizePerPageList = (sizePerPage) => {
    this.setState({ sizePerPage, page: 1 }, this.fetchData)
  };

  render() {
    const {
      specialRequests,
      page,
      sizePerPage,
      totalSpecialRequests,
      loading,
      selectedGuestSpecialRequests,
      selectedGuestSpecialRequestsVendorResponse,
      selectedGuestSpecialRequestsVendorHasResponded,
      selectedGuestEmail,
      selectedOrderId,
      selectedOrderItemId,
      showGuestSpecialRequestsResponseModal,
    } = this.state
    return (
      <div>
        {loading && (
          <div className="spinner-container overlay">
            <MDSpinner className="spinner" size={100} />
          </div>
        )}
        <SpecialRequestsResponseModal
          sendGuestSpecialRequestsResponse={
            this.sendGuestSpecialRequestsResponse
          }
          showGuestSpecialRequestsResponseModal={
            showGuestSpecialRequestsResponseModal
          }
          closeGuestSpecialRequestsResponseModal={
            this.closeGuestSpecialRequestsResponseModal
          }
          selectedGuestSpecialRequests={selectedGuestSpecialRequests}
          selectedGuestSpecialRequestsVendorResponse={
            selectedGuestSpecialRequestsVendorResponse
          }
          selectedGuestSpecialRequestsVendorHasResponded={
            selectedGuestSpecialRequestsVendorHasResponded
          }
          selectedGuestEmail={selectedGuestEmail}
          selectedOrderId={selectedOrderId}
          selectedOrderItemId={selectedOrderItemId}
        />
        <BootstrapTable
          data={specialRequests}
          fetchInfo={{
            dataTotalSize: totalSpecialRequests,
          }}
          options={{
            page,
            sizePerPage,
            onPageChange: this.onPageChange,
            onSizePerPageList: this.onSizePerPageList,
          }}
          remote
          pagination
        >
          <TableHeaderColumn dataFormat={this.renderOfferLink} width="13%">
            Offer ID
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.renderCreatedAtDate}
            dataField="created_at"
            isKey
            width="7%"
          >
            Date of request
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderPrimaryGuest} width="10%">
            Primary Guest
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderGuests}>
            Guests
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderPackage}>
            Package
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderRoomName}>
            Room name
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderCheckIn} width="7%">
            Check-in
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderCheckOut} width="7%">
            Check-out
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.renderGuestSpecialRequest}
            width="20%"
          >
            Special request
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderAction} width="10%">
            Respond
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    )
  }
}
