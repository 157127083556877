import React, { Component } from 'react'
import VendorService from '../../services/VendorService'
import { Button } from 'react-bootstrap'
import MDSpinner from 'react-md-spinner'
import ErrorDisplay from '../Common/ErrorDisplay'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import SubVendorEditorModal from './SubVendorEditorModal'

export default class SubVendorUserTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      openModal: false,
      datesRequests: [],
      error: null,
      cachedProperties: {},
    }
  }

  componentDidMount = async() => {
    this.fetchData()
  }

  fetchData = async() => {
    this.setState({ loading: true, error: null })
    const { vendorId, subVendorId } = this.props
    try {
      const { result: datesRequests } = await VendorService.listSubVendorUsers(vendorId, subVendorId)

      this.setState({ datesRequests: datesRequests })
    } catch (e) {
      this.setState({ error: e.message })
    } finally {
      this.setState({ loading: false })
    }
  }

  onPageChange = (page) => {
    this.setState({ page }, this.fetchData)
  };

  removeUser = async userId => {
    try {
      this.setState({ loading: true })
      const { vendorId, subVendorId } = this.props
      await VendorService.deleteSubVendorUser(vendorId, subVendorId, userId)
      await this.fetchData()
    } catch (e) {
      this.setState({ error: e.message })
    } finally {
      this.setState({ loading: false })
    }
  }

  renderActions = (cell, row) => <div>
    <Button onClick={() => this.removeUser(row.id_member)}>Remove</Button>
  </div>

  openEditorModal = () => {
    this.setState({ openModal: true })
  };

  onEditorClose = (reload) => {
    this.setState({ openModal: false })
    if (reload) {
      this.fetchData()
    }
  };

  render() {
    const {
      datesRequests,
      totalDatesRequests,
      loading,
      openModal,
    } = this.state
    const { vendorId, subVendorId } = this.props

    return (
      <div className="container dates-requests">
        {this.state.error && (
          <div className="container">
            <ErrorDisplay message={this.state.error} />
          </div>
        )}
        {loading && (
          <div className="spinner-container overlay">
            <MDSpinner className="spinner" size={50} />
          </div>
        )}

        <div
          className="headings"
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <h4>Users</h4>
          <div>
            <Button
              bsStyle="primary"
              onClick={this.openEditorModal}
            >
              Add User
            </Button>
          </div>
        </div>

        <SubVendorEditorModal
          idVendor={vendorId}
          idSubVendor={subVendorId}
          openModal={openModal}
          closeModal={this.onEditorClose}>
        </SubVendorEditorModal>

        <div style={{ marginTop: '12px' }}>
          <BootstrapTable
            data={datesRequests}
            fetchInfo={{ dataTotalSize: totalDatesRequests }}
            style={{ marginTop: '12px' }}
          >
            <TableHeaderColumn dataField="full_name">Name</TableHeaderColumn>
            <TableHeaderColumn isKey={true} dataField="id_member" dataFormat={this.renderActions} width="100px">Actions</TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    )
  }
}
