import React, { Component } from 'react'
import MDSpinner from 'react-md-spinner'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import moment from 'moment'
import ErrorDisplay from '../Common/ErrorDisplay'
import VendorService from '../../services/VendorService'
import { Link } from 'react-router'

export default class SubVendorTablePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1,
      sizePerPage: 10,
      totalDatesRequests: 0,
      loading: true,
      openModal: false,
      datesRequests: [],
      error: null,
      queryDatesRequestId: props.location.query?.datesRequestId,
      cachedProperties: {},
    }
    this.vendorId = props.params.id_vendor
  }

  componentDidMount = async() => {
    this.fetchData()
  }

  handlePageChange = page => {
    this.setState({ page: page }, this.fetchData)
  }

  fetchData = async() => {
    this.setState({ loading: true, error: null })
    const { page, sizePerPage } = this.state
    try {
      const { rows: datesRequests, total } = await VendorService.listSubVendors(this.vendorId, page, sizePerPage)

      this.setState({
        loading: false,
        datesRequests: datesRequests,
        totalDatesRequests: parseInt(total),
      })
    } catch (e) {
      this.setState({ loading: false, error: e.message })
    }
  }

  onPageChange = (page) => {
    this.setState({ page }, this.fetchData)
  };

  renderLeadPassenger = (cell, row) => {
    const passenger = row.passengers?.find(p => p.isLead)
    return <div className="offer-id">{passenger?.name ?? '-'}</div>
  };

  renderDate = (key) => (cell, row) => moment.utc(row[key]).format('DD/MM/YYYY');

  renderActions = (cell, row) => <div>
    <Link to={`/${this.vendorId}/vendors/${row.id_sub_vendor}`} activeClassName="active">Details</Link>
  </div>

  render() {
    const {
      datesRequests,
      page,
      sizePerPage,
      totalDatesRequests,
      loading,
    } = this.state

    return (
      <div className="container dates-requests">
        {this.state.error && (
          <div className="container">
            <ErrorDisplay message={this.state.error} />
          </div>
        )}
        {loading && (
          <div className="spinner-container overlay">
            <MDSpinner className="spinner" size={50} />
          </div>
        )}

        <div className="headings">
          <h3>Vendors</h3>
        </div>

        <div style={{ marginTop: '12px' }}>
          <BootstrapTable
            data={datesRequests}
            fetchInfo={{
              dataTotalSize: totalDatesRequests,
            }}
            options={{
              page,
              sizePerPage,
              sizePerPageList: [],
              onPageChange: this.onPageChange,
            }}
            remote
            pagination
            style={{ marginTop: '12px' }}
          >
            <TableHeaderColumn dataField="name">Name</TableHeaderColumn>
            <TableHeaderColumn isKey={true} dataField="id_member" dataFormat={this.renderActions} width="100px">Actions</TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    )
  }
}
