import React, { Component } from 'react'
import { Button } from 'react-bootstrap'

export default class ExpandableText extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMore: false,
    }
  }

  handleToggle = () => {
    this.setState(prevState => ({
      showMore: !prevState.showMore,
    }))
  }

  render() {
    const { value } = this.props
    const { showMore } = this.state

    if (!value) {
      return <p className="mb-0">N/A</p>
    }

    const isLongText = value.length > 40
    const displayText = (!showMore && isLongText) ? `${value.substring(0, 40)}...` : value

    return (
      <span>
        <p className="mb-0">{displayText}</p>
        {isLongText && (
          <Button bsStyle="link" bsSize="xsmall" onClick={this.handleToggle}>
            {showMore ? 'Less' : 'More'}
          </Button>
        )}
      </span>
    )
  }
}
