import React, { Component } from 'react'
import MDSpinner from 'react-md-spinner'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import TourService from '../../services/TourService'
import moment from 'moment'
import ErrorDisplay from '../Common/ErrorDisplay'

export default class DMCReservationPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1,
      sizePerPage: 10,
      totalDatesRequests: 0,
      loading: true,
      openModal: false,
      datesRequests: [],
      error: null,
      queryDatesRequestId: props.location.query?.datesRequestId,
      cachedProperties: {},
    }
    this.vendorId = props.params.id_vendor
  }

  componentDidMount = async() => {
    this.fetchData()
  }

  handlePageChange = page => {
    this.setState({ page: page }, this.fetchData)
  }

  fetchData = async() => {
    this.setState({ loading: true, error: null })
    const { page, sizePerPage } = this.state
    try {
      const { rows: datesRequests, total } = await TourService.listVendorReservations(this.vendorId, {
        page,
        pageSize: sizePerPage,
      })

      this.setState({
        loading: false,
        datesRequests: datesRequests,
        totalDatesRequests: parseInt(total),
      })
    } catch (e) {
      this.setState({ loading: false, error: e.message })
    }
  }

  onPageChange = (page) => {
    this.setState({ page }, this.fetchData)
  };

  renderLeadPassenger = (cell, row) => {
    const passenger = row.passengers?.find(p => p.isLead)
    return <div className="offer-id">{passenger?.name ?? '-'}</div>
  };

  renderDate = (key) => (cell, row) => moment.utc(row[key]).format('DD/MM/YYYY');

  renderRoomConfiguration = (cell, row) => {
    const config = row.rooms.reduce((acc, room) => {
      if (!acc[room.type]) {
        acc[room.type] = 1
      } else {
        acc[room.type] += 1
      }
      return acc
    }, {})

    return <div>{Object.entries(config).map(([key, value]) => `${value} x ${key}`).join('</br>')}</div>
  };

  renderTotalGuests = (cell, row) => {
    const totalGuests = row.rooms.reduce((acc, room) => acc + room.guests, 0)
    return totalGuests
  };

  render() {
    const {
      openModal,
      datesRequests,
      page,
      sizePerPage,
      totalDatesRequests,
      loading,
      approvalModalShow,
    } = this.state

    return (
      <div className="container dates-requests">
        {this.state.error && (
          <div className="container">
            <ErrorDisplay message={this.state.error} />
          </div>
        )}
        {loading && (
          <div className="spinner-container overlay">
            <MDSpinner className="spinner" size={50} />
          </div>
        )}

        <div className="headings">
          <h3>Reservations</h3>
        </div>

        <div style={{ marginTop: '12px' }}>
          <BootstrapTable
            data={datesRequests}
            fetchInfo={{
              dataTotalSize: totalDatesRequests,
            }}
            options={{
              page,
              sizePerPage,
              sizePerPageList: [],
              onPageChange: this.onPageChange,
            }}
            remote
            pagination
            style={{ marginTop: '12px' }}
          >
            <TableHeaderColumn dataField="startDate" dataFormat={this.renderDate('startDate')} isKey>Departure Date</TableHeaderColumn>
            <TableHeaderColumn dataField="seasonName" width="15%">Season</TableHeaderColumn>
            <TableHeaderColumn dataField="tourName">Tour Name</TableHeaderColumn>
            <TableHeaderColumn dataField="startlocation">Departure City</TableHeaderColumn>
            <TableHeaderColumn dataFormat={this.renderDate('endDate')}>Handback Date</TableHeaderColumn>
            <TableHeaderColumn dataFormat={this.renderLeadPassenger}>Primary Guest Name</TableHeaderColumn>
            <TableHeaderColumn dataFormat={this.renderTotalGuests}># Guests</TableHeaderColumn>
            <TableHeaderColumn dataFormat={this.renderRoomConfiguration}>Bed configuration</TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    )
  }
}
