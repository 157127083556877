import React, { Component } from 'react'
import MDSpinner from 'react-md-spinner'
import moment from 'moment'
import ErrorDisplay from '../Common/ErrorDisplay'
import VendorService from '../../services/VendorService'
import SubVendorUserTable from './SubVendorUserTable'

export default class SubVendorDetailsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      openModal: false,
      vendor: {},
      error: null,
      queryDatesRequestId: props.location.query?.datesRequestId,
      cachedProperties: {},
    }
  }

  componentDidMount = async() => {
    this.fetchData()
  }

  fetchData = async() => {
    this.setState({ loading: true, error: null })
    const { id_vendor, id_sub_vendor } = this.props.params
    try {
      const details = await VendorService.getSubVendor(id_vendor, id_sub_vendor)
      this.setState({ vendor: details })
    } catch (e) {
      this.setState({ error: e.message })
    } finally {
      this.setState({ loading: false })
    }
  }

  renderDate = (key) => (cell, row) => moment.utc(row[key]).format('DD/MM/YYYY');

  render() {
    const {
      vendor,
      loading,
    } = this.state
    const { id_vendor, id_sub_vendor } = this.props.params

    return (
      <div className="container dates-requests">
        {this.state.error && (
          <div className="container">
            <ErrorDisplay message={this.state.error} />
          </div>
        )}
        {loading && (
          <div className="spinner-container overlay">
            <MDSpinner className="spinner" size={50} />
          </div>
        )}

        <div className="headings">
          <h3>{vendor.name}</h3>
        </div>

        <SubVendorUserTable vendorId={id_vendor} subVendorId={id_sub_vendor}></SubVendorUserTable>
      </div>
    )
  }
}
